import "./App.css";
import LoginPage from "./pages/login-page/login-page.component";
import { UserContext } from "./context/user.context";
import { useContext, useEffect } from "react";
import Dashboard from "./pages/dashboard/dashboard.component";

function App() {
  const { status } = useContext(UserContext);

  useEffect(() => {
    if (!status) document.title = "Login";
    else document.title = "Sites";
  }, [status]);
  return <div className="App">{status ? <Dashboard /> : <LoginPage />}</div>;
}

export default App;
