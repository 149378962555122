import "./category.style.css";
export default function Category({ categories }) {
  const categoriesArr = categories.split(",");
  return (
    <div className="table__categories">
      {categoriesArr.map((cat, i) => (
        <span className="table__category" key={i}>
          {cat}
        </span>
      ))}
    </div>
  );
}
