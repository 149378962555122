import React, { createContext, useState, useEffect } from "react";

// Create context
export const FiltersContext = createContext({
  filters: null,
  setFilters: () => {},
});

// Create provider
export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    keyword_column: "all",
    keyword: "",
    dr: "all",
    dr_min: 0,
    dr_max: 0,
    rd: "all",
    rd_min: 0,
    rd_max: 0,
    backlinks: "all",
    backlinks_min: 0,
    backlinks_max: 0,
    content_length: "all",
    content_length_min: 0,
    content_length_max: 0,
    traffic: "all",
    traffic_min: 0,
    traffic_max: 0,
    price_general: "all",
    price_general_min: 0,
    price_general_max: 0,
    price_cbd: "all",
    price_cbd_min: 0,
    price_cbd_max: 0,
    price_casino: "all",
    price_casino_min: 0,
    price_casino_max: 0,
    price_crypto: "all",
    price_crypto_min: 0,
    price_crypto_max: 0,
    price_language_other: "all",
    price_language_other_min: 0,
    price_language_other_max: 0,
    price_link_insert: "all",
    price_link_insert_min: 0,
    price_link_insert_max: 0,
    order_column: "",
    order: "desc",
    rows: 20,
  });
  const handleFilterChange = (name, value) => {
    setFilters((prevFil) => {
      return {
        ...prevFil,
        [name]: value,
      };
    });
  };

  const value = { filters, handleFilterChange, setFilters };
  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};
