import { useState } from "react";
import { TextField, InputLabel } from "@mui/material";

export default function Search({ name, onNewFilterChange, label }) {
  const [value, setValue] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  function handleSearch(e) {
    setValue(e.target.value);
    onNewFilterChange(e.target.name, e.target.value);
  }
  return (
    <div className="search">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <TextField
        sx={{ width: "100%" }}
        onChange={handleSearch}
        value={value}
        id="outlined-basic"
        placeholder="Search"
        name={name}
        // label={label}
        variant="outlined"
      />
    </div>
  );
}
