import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";

import { useState } from "react";

export default function OptionSelect({
  onNewFilterChange,
  label,
  name,
  options,
}) {
  const [value, setValue] = useState("");
  function handleToggle(e, value) {
    setValue(e.target.value);
    onNewFilterChange(name, !value ? "all" : value);
  }

  return (
    <div className="select-minmax">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        onChange={handleToggle}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
}
