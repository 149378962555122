import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import { TextField } from "@mui/material";

export default function SelectMenu({
  onNewFilterChange,
  label,
  name,
  options,
}) {
  const [value, setValue] = useState(options[0].value);
  function handleToggle(e) {
    setValue(e.target.value);
    onNewFilterChange(e.target.name, e.target.value);
  }
  return (
    <div className="select-minmax">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        sx={{ width: "100%" }}
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        label={label}
        onChange={handleToggle}
        name={name}
      >
        {options.map((opt, i) => {
          return (
            <MenuItem key={i} value={opt.value}>
              {opt.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
