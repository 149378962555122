import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState, useEffect, useContext } from "react";
import { TextField } from "@mui/material";
import { FiltersContext } from "../../context/filters.context";

export default function SelectMinMax({
  maxData,
  onNewFilterChange,
  label,
  name,
  onPageChange,
}) {
  const { filters } = useContext(FiltersContext);

  const [value, setValue] = useState("all");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState("");
  useEffect(() => {
    setMax(filters[`${name}_max`]);
  }, [maxData, name]);

  function handleToggle(e) {
    setValue(e.target.value);
    onNewFilterChange(e.target.name, e.target.value);
  }
  function handleMin(e) {
    const newValue = e.target.value;

    // Check if the new value is a number and is greater than or equal to zero.
    if (
      /^\d*\.?\d{0,2}$/.test(newValue) &&
      (newValue === "" || newValue >= 0)
    ) {
      setMin(newValue);
      onNewFilterChange(e.target.name, e.target.value);
    }
  }
  function handleMax(e) {
    const newValue = e.target.value;

    // Check if the new value is a number and is greater than or equal to zero.
    if (
      /^\d*\.?\d{0,2}$/.test(newValue) &&
      (newValue === "" || newValue >= 0)
    ) {
      setMax(newValue);
      onNewFilterChange(e.target.name, e.target.value);
    }
  }
  return (
    <div className="select-minmax">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        sx={{ width: "100%" }}
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        label={label}
        onChange={handleToggle}
        name={name}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="range">Range</MenuItem>
        <MenuItem value="na">Not Attempt</MenuItem>
      </Select>

      <div className="filters__minmax">
        <div>
          <InputLabel id="demo-select-small-label">Min</InputLabel>
          <TextField
            type="number"
            disabled={value !== "range" ? true : false}
            onChange={handleMin}
            style={
              value !== "range" ? { backgroundColor: "hsl(0, 0%, 95%)" } : {}
            }
            value={min}
            id="outlined-basic"
            // label="Min"
            name={`${name}_min`}
            variant="outlined"
          />
        </div>
        <div>
          <InputLabel id="demo-select-small-label">Max</InputLabel>
          <TextField
            disabled={value !== "range" ? true : false}
            type="number"
            style={
              value !== "range" ? { backgroundColor: "hsl(0, 0%, 95%)" } : {}
            }
            value={max}
            name={`${name}_max`}
            onChange={handleMax}
            id="outlined-basic"
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
}
