import "./table-row.style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import Category from "../category/category.component";
import NullCell from "../null-cell/null-cell.component";
import EditModel from "../edit-model/edit-model.component";
import { Checkbox } from "@mui/material";

export default function TableRow({
  site,
  onDeleteSite,
  onEditSite,
  setSelectedIds,
  selectAll,
  setSelectAll,
  selectType,
  setSelectType,
}) {
  const [siteData, setSiteData] = useState(site);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [selected, setSelected] = useState(false);
  const {
    id,
    url,
    vendor,
    categories,
    dr,
    rd,
    traffic,
    content_length,
    backlinks,
    price_general,
    price_cbd,
    price_casino,
    price_crypto,
    price_language_other,
    price_link_insert,
    link_validity,
    link_type,
    note,
    language,
  } = siteData;
  function handleChecked(e) {
    setSelected(e.target.checked);
    if (e.target.checked) {
      setSelectedIds((ids) => [...ids, id]);
    } else {
      setSelectedIds((ids) => ids.filter((item) => item !== id));
      setSelectType("filter");
    }
  }

  useEffect(() => {
    if (selectType === "all") {
      setSelected(true);
      setSelectedIds((ids) => {
        if (ids.includes(id)) {
          return ids;
        } else {
          return [...ids, id];
        }
      });
    } else if (selectType === "none") {
      setSelected(false);
      setSelectedIds((ids) => ids.filter((item) => item !== id));
    } else if (selectType === "filter") {
      setSelectAll(false);
    }
  }, [selectType]);

  const formatNumber = (num) => {
    const absNum = Math.abs(num);
    let sign = num < 0 ? "-" : "";

    if (absNum >= 1000000) {
      return sign + (absNum / 1000000).toFixed(1) + "M"; // For numbers in millions
    } else if (absNum >= 1000) {
      return sign + (absNum / 1000).toFixed(1) + "K"; // For numbers in thousands
    } else {
      return num.toString(); // For numbers less than 1000
    }
  };
  if (!isDeleted)
    return (
      <>
        <tr
          onMouseEnter={() => {
            setShowActions(true);
          }}
          onMouseLeave={() => {
            setShowActions(false);
          }}
        >
          <td>
            <Checkbox checked={selected} onChange={handleChecked} />
          </td>
          <td>
            <a className="table__url" target="_blank" href={`https://${url}`}>
              {url || <NullCell />}
            </a>
          </td>
          <td>{vendor || <NullCell />}</td>
          <td style={{ width: "250px" }}>
            {categories ? <Category categories={categories} /> : "-"}
            {/* <Category categories={categories} /> */}
          </td>
          <td>{dr}</td>
          <td>{formatNumber(rd)}</td>
          <td>{formatNumber(traffic)}</td>
          <td>{formatNumber(content_length)}</td>
          <td>{formatNumber(backlinks)}</td>
          <td>{`$${price_general}`} </td>
          <td>{`$${price_cbd}`} </td>
          <td>{`$${price_casino}`}</td>
          <td>{`$${price_crypto}`} </td>
          <td>{`$${price_language_other}`} </td>
          <td>{`$${price_link_insert}`}</td>

          <td>{link_type || "-"}</td>
          <td>{link_validity || "-"}</td>
          <td>{language || "-"}</td>
          <td>{note || "-"}</td>
          <td style={{ cursor: "pointer" }}>
            <div className={`actions ${!showActions ? "actions--hidden" : ""}`}>
              <span
                onClick={() => {
                  setIsDeleted(true);
                  onDeleteSite(id);
                }}
                style={{
                  backgroundColor: "#E45454",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginRight: "5px",
                  color: "white",
                }}
              >
                Delete
              </span>

              <span
                onClick={() => {
                  setIsEdit(true);
                }}
                style={{
                  backgroundColor: "#1565c0",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginRight: "5px",
                  color: "white",
                }}
              >
                Edit
              </span>
            </div>
          </td>
        </tr>
        {isEdit && (
          <tr>
            <td>
              <EditModel
                setIsEdit={setIsEdit}
                onEditSite={onEditSite}
                site={siteData}
                setSiteData={setSiteData}
              />
            </td>
          </tr>
        )}
      </>
    );
  else return null;
}
