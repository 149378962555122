import { useState } from "react";
import "./file-upload.style.css";
import { Button } from "@mui/material";

export default function FileUpload({ onUploadFile }) {
  const [file, setFile] = useState();

  function handleFile(event) {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
    onUploadFile(event.target.files[0]);
  }
  return (
    <div className="file-upload">
      <form>
        <input
          id="file-uploader"
          type="file"
          name="file"
          onChange={handleFile}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            document.querySelector("#file-uploader").click();
          }}
          size="large"
          variant="contained"
        >
          Import
        </Button>
      </form>
    </div>
  );
}
