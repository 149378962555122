import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context/user.context";
import "./table.style.css";
import TableRow from "../table-row/table-row.component";
import { Checkbox, CircularProgress } from "@mui/material";

export default function Table({
  selectType,
  setSelectType,
  selectAll,
  setSelectAll,
  sites,
  onDeleteSite,
  setSelectedIds,
  onEditSite,
}) {
  function handleCheck(e) {
    if (e.target.checked) {
      setSelectAll(true);
      setSelectType("all");
    }
    if (!e.target.checked) {
      setSelectAll(false);
      setSelectType("none");
    }
  }
  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            {/* <th>Toggle</th> */}
            <th>
              <Checkbox
                onChange={handleCheck}
                style={{ backgroundColor: "white" }}
                color="default"
                checked={selectAll}
              />
            </th>
            <th>Url</th>
            <th>Vendor</th>
            <th>Categories</th>
            <th>DR</th>
            <th>RD</th>
            <th>Traffic</th>
            <th>Content Length</th>
            <th>Backlinks</th>
            <th>Price General</th>
            <th>Price CBD</th>
            <th>Price Casino</th>
            <th>Price Crypto</th>
            <th>Price Language Other</th>
            <th>Price Link Insert</th>
            <th>Link Type</th>
            <th>Link Validity</th>
            <th>Language</th>
            <th>Note</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sites.length > 0 &&
            sites.map((site) => (
              <TableRow
                selectType={selectType}
                setSelectType={setSelectType}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                setSelectedIds={setSelectedIds}
                onEditSite={onEditSite}
                onDeleteSite={onDeleteSite}
                key={site.id}
                site={site}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}
