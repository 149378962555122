import { Button } from "@mui/material";
import AddModel from "../add-model/add-model.component";
import { useEffect, useState } from "react";
import FileUpload from "../file-upload/file-upload.component";
import ExportBtn from "../export-btn/export-btn.component";

export default function FeaturesMenu({
  onUploadFile,
  onAddSite,
  selectedIds,
  handleBulkDelete,
  handleExportFile,
  sites
}) {
  const [showModel, setShowModel] = useState(false);
  const [showBulkDelete, setShowBulkDelete] = useState(false);
  useEffect(() => {
    if (selectedIds.length > 0) setShowBulkDelete(true);
    else setShowBulkDelete(false);
  }, [selectedIds]);
  function handelClick() {
    setShowModel(true);
  }
  return (
    <div
      style={{ display: "flex", justifyContent: "end", marginBottom: "10px" }}
      className="features-menu"
    >
      {showModel && (
        <AddModel onAddSite={onAddSite} setShowModel={setShowModel} />
      )}
      <div
        className="menu-button-container"
        style={{ display: "flex", gap: "10px" }}
      >
        {showBulkDelete && (
          <Button
            size="large"
            variant="contained"
            color="error"
            onClick={handleBulkDelete}
          >
            Delete
          </Button>
        )}
        <Button size="large" variant="contained" onClick={handelClick}>
          Add
        </Button>
        <FileUpload onUploadFile={onUploadFile}  />
        <ExportBtn handleExportFile={handleExportFile} data={sites}/>
      </div>
    </div>
  );
}
