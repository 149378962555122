import "./edit-model.style.css";
import { useState } from "react";

export default function EditModel({
  site,
  onEditSite,
  setIsEdit,
  setSiteData,
}) {
  const [DR, setDR] = useState(site.dr);

  const [RD, setRD] = useState(site.rd);
  const [traffic, setTraffic] = useState(site.traffic);
  const [backlinks, setBacklinks] = useState(site.backlinks);
  const [contentLength, setContentLength] = useState(site.content_length);
  const [general, setGeneral] = useState(site.price_general);
  const [CBD, setCBD] = useState(site.price_cbd);
  const [casino, setCasino] = useState(site.price_casino);
  const [crypto, setCrypto] = useState(site.price_crypto);
  const [languageOther, setLanguageOther] = useState(site.price_language_other);
  const [linkInsert, setLinkInsert] = useState(site.price_link_insert);
  const [linkType, setLinkType] = useState(site.link_type);
  const [linkValidity, setLinkValidity] = useState(site.link_validity);
  const [note, setNote] = useState(site.note);

  function handleSubmit(e) {
    e.preventDefault();
    const newObj = {
      id: site.id,
      url: site.url,
      categories: site.categories,
      vendor: site.vendor,
      rd: RD,
      dr: DR,
      traffic,
      backlinks,
      content_length: contentLength,
      price_general: general,
      price_cbd: CBD,
      price_casino: casino,
      price_crypto: crypto,
      price_language_other: languageOther,
      price_link_insert: linkInsert,
      link_type: linkType,
      link_validity: linkValidity,
      note,
    };
    setIsEdit(false);
    setSiteData(newObj);
    onEditSite(newObj);
    console.log(site);
  }

  return (
    <div className="model-overlay">
      <div className="model-container">
        <form className="model__form" action="">
          <h3 className="model__title">{site.url}</h3>

          <div className="input__group">
            <label className="model__label" htmlFor="url">
              DR
            </label>
            <input
              onChange={(e) => {
                setDR(e.target.value);
              }}
              value={DR}
              name="dr"
              className="model__input"
              type="number"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              RD
            </label>
            <input
              onChange={(e) => {
                setRD(e.target.value);
              }}
              value={RD}
              name="rd"
              className="model__input"
              type="number"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Traffic
            </label>
            <input
              onChange={(e) => {
                setTraffic(e.target.value);
              }}
              value={traffic}
              name="traffic"
              className="model__input"
              type="number"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Backlinks
            </label>
            <input
              onChange={(e) => {
                setBacklinks(e.target.value);
              }}
              value={backlinks}
              name="backlinks"
              className="model__input"
              type="number"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Content Length
            </label>
            <input
              onChange={(e) => {
                setContentLength(e.target.value);
              }}
              value={contentLength}
              name="content_length"
              className="model__input"
              type="number"
            />
          </div>
          <div className="input-group-3-col">
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price General
              </label>
              <input
                onChange={(e) => {
                  setGeneral(e.target.value);
                }}
                value={general}
                name="price_general"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price CBD
              </label>
              <input
                onChange={(e) => {
                  setCBD(e.target.value);
                }}
                value={CBD}
                name="price_cbd"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Casino
              </label>
              <input
                onChange={(e) => {
                  setCasino(e.target.value);
                }}
                value={casino}
                name="price_casino"
                className="model__input"
                type="number"
              />
            </div>
          </div>
          <div className="input-group-3-col">
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Crypto
              </label>
              <input
                onChange={(e) => {
                  setCrypto(e.target.value);
                }}
                value={crypto}
                name="price_crypto"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Language Other
              </label>
              <input
                onChange={(e) => {
                  setLanguageOther(e.target.value);
                }}
                value={languageOther}
                name="price_language_other"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Link Insert
              </label>
              <input
                onChange={(e) => {
                  setLinkInsert(e.target.value);
                }}
                value={linkInsert}
                name="price_link_insert"
                className="model__input"
                type="number"
              />
            </div>
          </div>

          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Link Type
            </label>
            <input
              onChange={(e) => {
                setLinkType(e.target.value);
              }}
              value={linkType}
              name="link_type"
              className="model__input"
              type="text"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Link Validity
            </label>
            <input
              onChange={(e) => {
                setLinkValidity(e.target.value);
              }}
              value={linkValidity}
              name="link_validity"
              className="model__input"
              type="text"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Note
            </label>
            <input
              onChange={(e) => {
                setNote(e.target.value);
              }}
              value={note}
              name="note"
              className="model__input"
              type="text"
            />
          </div>
          <button onClick={handleSubmit} className="model__btn">
            Submit
          </button>
          <button
            onClick={() => {
              console.log(site);
              setIsEdit(false);
            }}
            className="model__btn"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
}
