import React, { createContext, useState, useEffect } from "react";

// Create context
export const UserContext = createContext({});

// Create provider
export const UserProvider = ({ children }) => {
  const initialStatus = localStorage.getItem("status") === "true";
  const initialToken = localStorage.getItem("token") || null;
  const [status, setStatus] = useState(initialStatus);
  const [token, setToken] = useState(initialToken);
  // const [status, setStatus] = useState(localStorage.getItem("status") || false);
  // const [token, setToken] = useState(localStorage.getItem("token") || null);

  const changeUserState = (newStatus, newToken) => {
    setStatus(newStatus);
    setToken(newToken);
  };

  // Whenever status or token changes, update localStorage
  useEffect(() => {
    localStorage.setItem("status", status);
    localStorage.setItem("token", token);
  }, [status, token]);

  return (
    <UserContext.Provider value={{ status, token, changeUserState }}>
      {children}
    </UserContext.Provider>
  );
};
