import { useState } from "react";

export default function AddModel({ setShowModel, onAddSite }) {
  const [url, setUrl] = useState("");
  const [categories, setCategories] = useState("");
  const [vendor, setVendor] = useState("");
  const [DR, setDR] = useState("" || 0);
  const [RD, setRD] = useState("" || 0);
  const [traffic, setTraffic] = useState("" || 0);
  const [backlinks, setBacklinks] = useState("" || 0);
  const [contentLength, setContentLength] = useState("" || 0);
  const [general, setGeneral] = useState("" || "0.00");
  const [CBD, setCBD] = useState("" || "0.00");
  const [casino, setCasino] = useState("" || "0.00");
  const [crypto, setCrypto] = useState("" || "0.00");
  const [languageOther, setLanguageOther] = useState("" || "0.00");
  const [linkInsert, setLinkInsert] = useState("" || "0.00");
  const [linkType, setLinkType] = useState("");
  const [linkValidity, setLinkValidity] = useState("");
  const [language, setLanguage] = useState("");
  const [note, setNote] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const newObj = {
      url,
      categories,
      vendor,
      rd: RD,
      dr: DR,
      traffic,
      backlinks,
      content_length: contentLength,
      price_general: general,
      price_cbd: CBD,
      price_casino: casino,
      price_crypto: crypto,
      price_language_other: languageOther,
      price_link_insert: linkInsert,
      link_type: linkType,
      link_validity: linkValidity,
      language,
      note,
    };
    setShowModel(false);
    onAddSite(newObj);
  }

  return (
    <div className="model-overlay">
      <div className="model-container">
        <form className="model__form" onSubmit={handleSubmit}>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              URL
            </label>
            <input
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              value={url}
              name="url"
              className="model__input"
              required
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="categories">
              Categories
            </label>
            <input
              onChange={(e) => {
                setCategories(e.target.value);
              }}
              required
              value={categories}
              name="categories"
              className="model__input"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="vendor">
              Vendor
            </label>
            <input
              onChange={(e) => {
                setVendor(e.target.value);
              }}
              required
              value={vendor}
              name="vendor"
              className="model__input"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="vendor">
              Language
            </label>
            <input
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
              required
              value={language}
              name="language"
              className="model__input"
            />
          </div>
          <div className="input-group-2-col">
            <div className="input__group">
              <label className="model__label" htmlFor="vendor">
                Backlinks
              </label>
              <input
                onChange={(e) => {
                  setBacklinks(e.target.value);
                }}
                required
                value={backlinks}
                name="backlinks"
                className="model__input"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="vendor">
                Content Length
              </label>
              <input
                onChange={(e) => {
                  setContentLength(e.target.value);
                }}
                required
                value={contentLength}
                name="content_length"
                className="model__input"
              />
            </div>
          </div>
          <div className="input-group-3-col">
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                DR
              </label>
              <input
                onChange={(e) => {
                  setDR(e.target.value);
                }}
                value={DR}
                name="dr"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                RD
              </label>
              <input
                onChange={(e) => {
                  setRD(e.target.value);
                }}
                value={RD}
                name="rd"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Traffic
              </label>
              <input
                onChange={(e) => {
                  setTraffic(e.target.value);
                }}
                value={traffic}
                name="traffic"
                className="model__input"
                type="number"
              />
            </div>
          </div>
          <div className="input-group-3-col">
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price General
              </label>
              <input
                onChange={(e) => {
                  setGeneral(e.target.value);
                }}
                value={general}
                name="price_general"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price CBD
              </label>
              <input
                onChange={(e) => {
                  setCBD(e.target.value);
                }}
                value={CBD}
                name="price_cbd"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Casino
              </label>
              <input
                onChange={(e) => {
                  setCasino(e.target.value);
                }}
                value={casino}
                name="price_casino"
                className="model__input"
                type="number"
              />
            </div>
          </div>
          <div className="input-group-3-col">
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Crypto
              </label>
              <input
                onChange={(e) => {
                  setCrypto(e.target.value);
                }}
                value={crypto}
                name="price_crypto"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Language Other
              </label>
              <input
                onChange={(e) => {
                  setLanguageOther(e.target.value);
                }}
                value={languageOther}
                name="price_language_other"
                className="model__input"
                type="number"
              />
            </div>
            <div className="input__group">
              <label className="model__label" htmlFor="url">
                Price Link Insert
              </label>
              <input
                onChange={(e) => {
                  setLinkInsert(e.target.value);
                }}
                value={linkInsert}
                name="price_link_insert"
                className="model__input"
                type="number"
              />
            </div>
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Link Type
            </label>
            <input
              onChange={(e) => {
                setLinkType(e.target.value);
              }}
              value={linkType}
              name="link_type"
              className="model__input"
              type="text"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Link Validity
            </label>
            <input
              onChange={(e) => {
                setLinkValidity(e.target.value);
              }}
              value={linkValidity}
              name="link_validity"
              className="model__input"
              type="text"
            />
          </div>
          <div className="input__group">
            <label className="model__label" htmlFor="url">
              Note
            </label>
            <input
              onChange={(e) => {
                setNote(e.target.value);
              }}
              value={note}
              name="note"
              className="model__input"
              type="text"
            />
          </div>
          <button type="submit" className="model__btn">
            Submit
          </button>
          <button
            onClick={() => {
              setShowModel(false);
            }}
            className="model__btn"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
}
