import InputLabel from "@mui/material/InputLabel";

import { useState, useContext } from "react";
import { TextField } from "@mui/material";
import { FiltersContext } from "../../context/filters.context";

export default function PageRows({onNewFilterChange}) {
  const { filters } = useContext(FiltersContext);
  const [value, setValue] = useState(filters.rows);

  function handleRows(e) {
    const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    setValue(inputValue);
    onNewFilterChange(e.target.name, e.target.value)
    
  }
  return (
    <div className="filters__rows">
    <InputLabel id="demo-select-small-label">Page Rows</InputLabel>
    <TextField
        type="text"
        onChange={handleRows}
        value={value}
        id="outlined-basic"
        name={`rows`}
        variant="outlined"
        inputProps={{ inputMode: "numeric" }}
        />
    </div>
  );
}
