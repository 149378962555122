import { useContext, useEffect, useState } from "react";
import "./login-page.style.css";
import { UserContext } from "../../context/user.context";

export default function LoginPage() {
  const [form, setForm] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState(null);

  const { changeUserState } = useContext(UserContext);

  function handleInputChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function authUser(email, password) {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const response = await fetch("https://backend.codewhen.com/api/login", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    if (data.status) {
      changeUserState(data.status, data.token);
    } else {
      setErrors(data.message);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.email || !form.password || !/\S+@\S+\.\S+/.test(form.email)) {
      setErrors("Email or Password is invalid");
    }
    authUser(form.email, form.password);
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit} action="" className="login__form form">
        {errors && <div className="error-message">{errors}</div>}

        <div className="form__input-group">
          {/* { (
            <p className="form__error">
              {errors.email ? errors.email : <span>&nbsp;</span>}
            </p>
          )} */}
          <input
            name="email"
            value={form.email}
            onChange={handleInputChange}
            placeholder="email"
            // type="email"
            className={`form__input input-field ${
              errors ? "input-field--invalid" : ""
            }`}
          />
        </div>

        <div className="form__input-group">
          {/* {
            <p className="form__error">
              {errors.password ? errors.password : <span>&nbsp;</span>}
            </p>
          } */}
          <input
            name="password"
            value={form.password}
            onChange={handleInputChange}
            placeholder="password"
            type="password"
            className={`form__input input-field ${
              errors ? "input-field--invalid" : ""
            }`}
          />
        </div>

        <button className="form__button button button--green">LOGIN</button>
      </form>
    </div>
  );
}
