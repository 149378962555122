import SelectMinMax from "../select-minmax/select-minmax.component";
import "./filters.style.css";
import { useState, useContext } from "react";
import { FiltersContext } from "../../context/filters.context";
import { Button } from "@mui/material";
import SelectMenu from "../select-menu/select-menu.component";
import Search from "../search/search.component";
import PageRows from "../page-rows/page-rows";
import OptionSelect from "../vendors-select/vendors-select.component";

export default function Filters({ onPageChange, maxData, vendors, language }) {
  const { filters, setFilters } = useContext(FiltersContext);
  const [newFilters, setNewFilters] = useState(filters);
  function handleNewFilterChange(name, value) {
    setNewFilters((prevFil) => {
      return {
        ...prevFil,
        [name]: value,
      };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setFilters(newFilters);
    onPageChange(1);
  }
  return (
    <div className="filters">
      <div className="filters-container">
        <div className="filters__col filters__col--1">
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"DR"}
            name={"dr"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"RD"}
            name={"rd"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Traffic"}
            name={"traffic"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Backlinks"}
            name={"backlinks"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Content Length"}
            name={"content_length"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            onPageChange={onPageChange}
            label={"Price General"}
            name={"price_general"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Price CBD"}
            name={"price_cbd"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Price Casino"}
            name={"price_casino"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Price Crypto"}
            name={"price_crypto"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Price Language Other"}
            name={"price_language_other"}
          />
          <SelectMinMax
            maxData={maxData}
            onNewFilterChange={handleNewFilterChange}
            label={"Price Link Insert"}
            name={"price_link_insert"}
          />
        </div>
        <div className="filters__col filters__col--2">
          <div className="searchbox">
            <SelectMenu
              name={"keyword_column"}
              options={[
                { value: "all", label: "All" },
                { value: "url", label: "URL" },
                { value: "categories", label: "Categories" },
                { value: "note", label: "Note" },
                { value: "link_type", label: "Link Type" },
                { value: "link_validity", label: "Link Validity" },
              ]}
              label={"Search Column"}
              onNewFilterChange={handleNewFilterChange}
            />
            <Search
              name={"keyword"}
              label={"Search"}
              onNewFilterChange={handleNewFilterChange}
            />
          </div>
          <OptionSelect
            options={vendors}
            onNewFilterChange={handleNewFilterChange}
            label={"Vendor"}
            name={"vendor"}
          />
          <OptionSelect
            options={language}
            onNewFilterChange={handleNewFilterChange}
            label={"Language"}
            name={"language"}
          />

          <div className="filters__sorting">
            <SelectMenu
              name={"order"}
              label={"Sorting Order"}
              options={[
                { value: "desc", label: "Descending" },
                { value: "asc", label: "Ascending" },
              ]}
              onNewFilterChange={handleNewFilterChange}
            />

            <SelectMenu
              name={"order_column"}
              label={"Sorting Column"}
              options={[
                { value: "id", label: "#" },
                { value: "dr", label: "DR" },
                { value: "rd", label: "RD" },
                { value: "traffic", label: "Traffic" },
                { value: "price_general", label: "General Price" },
                { value: "price_cbd", label: "CBD Price" },
                { value: "price_casino", label: "Casino Price" },
                { value: "price_crypto", label: "Casino Crypto" },
                {
                  value: "price_language_other",
                  label: "Casino Language Other",
                },
                { value: "price_link_insert", label: "Casino Link Insert" },
                { value: "backlinks", label: "Backlinks" },
                { value: "content_length", label: "Content Length" },
              ]}
              onNewFilterChange={handleNewFilterChange}
            />
          </div>
          <div className="btn__container">
            <PageRows onNewFilterChange={handleNewFilterChange} />
            <Button size="large" variant="contained" onClick={handleSubmit}>
              Filter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
