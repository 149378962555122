import "./pagination.style.css";
import { Pagination } from "@mui/material";
const page = 1;
export default function PaginationComponent({ lastPage, page, onPageChange }) {
  return (
    <div className="pagination">
      <Pagination
        onChange={(event, value) => {
          onPageChange(value);
        }}
        color="primary"
        count={+lastPage}
        showFirstButton
        showLastButton
        page={page}
      />
    </div>
  );
}
