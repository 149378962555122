import { Button } from "@mui/material";

export default function ExportBtn({data,handleExportFile}) {
    function HandleExport(){
        handleExportFile();
    }
    return (
        <Button size="large" variant="contained" onClick={HandleExport}>
            Export
        </Button>
)}